const { auto } = require("@popperjs/core");

(function($) {
    $(document).ready(function() {
        $('#main-carousel').on('initialized.owl.carousel', function(property){

            var carousel = $('#main-carousel');
            carousel.find(".owl-item").find(".title").css('opacity', '0');
            carousel.find(".owl-item").find(".text").css('opacity', '0');
            carousel.find(".owl-item").find(".btn-wrapper").css('opacity', '0');

            var current = property.item.index;
            var slide = $(property.target).find(".owl-item").eq(current);
            var title = $(property.target).find(".owl-item").eq(current).find(".title");
            var text = $(property.target).find(".owl-item").eq(current).find(".text");
            var btn = $(property.target).find(".owl-item").eq(current).find(".btn-wrapper");

            $(title).delay(750).queue(function (next) {
                $(this).css('opacity', '1');
                next();
            });

            $(text).delay(750).queue(function (next) {
                $(this).css('opacity', '1');
                next();
            });

            $(btn).delay(1250).queue(function (next) {
                $(this).css('opacity', '1');
                next();
            });
        });

        $('#main-carousel').owlCarousel({
            items: 1,
            nav 	: false,
            // navText: [
            //   "<i class='fa fa-3x fa-angle-left'></i>",
            //   "<i class='fa fa-3x fa-angle-right'></i>"
            // ],
            autoplay	: true,
            autoplayTimeout : 7000,
            autoplaySpeed : 1500,
            navSpeed : 1500,
            loop 	: $('.owl-carousel .item').length > 1 ? true : false,
            dots 	: true,
            responsiveRefreshRate   : 0
        });

        $('#main-carousel').on('changed.owl.carousel', function(property){

            var carousel = $('#main-carousel');
            carousel.find(".owl-item").find(".title").css('opacity', '0');
            carousel.find(".owl-item").find(".text").css('opacity', '0');
            carousel.find(".owl-item").find(".btn-wrapper").css('opacity', '0');

            var current = property.item.index;
            var slide = $(property.target).find(".owl-item").eq(current);
            var title = $(property.target).find(".owl-item").eq(current).find(".title");
            var text = $(property.target).find(".owl-item").eq(current).find(".text");
            var btn = $(property.target).find(".owl-item").eq(current).find(".btn-wrapper");

            $(title).delay(750).queue(function (next) {
                $(this).css('opacity', '1');
                next();
            });

            $(text).delay(750).queue(function (next) {
                $(this).css('opacity', '1');
                next();
            });

            $(btn).delay(1250).queue(function (next) {
                $(this).css('opacity', '1');
                next();
            });
        });

        $('#gallery-carousel, .gallery-carousel').owlCarousel({
            items: 1,
            nav 	: true,
            navText: [
              "<i class='fa fa-3x fa-angle-left'></i>",
              "<i class='fa fa-3x fa-angle-right'></i>"
            ],
            autoplay	:true,
            autoplayTimeout	: 6000,
            autoplaySpeed : 1500,
            navSpeed : 1500,
            loop 	: $('.owl-carousel .item').length > 1 ? true : false,
            dots 	: true,
            responsiveRefreshRate   : 0
        });

        /*$('#treatment-carousel').owlCarousel({
            items	: 1,
            nav 	: true,
            navText	: [
                "<i class='fa fa-chevron-left'></i>",
                "<i class='fa fa-chevron-right'></i>"
            ],
            loop 	: $('.owl-carousel .item').length > 1 ? true : false,
            dots 	: true,
            responsiveRefreshRate	: 0
        });*/

        /*$('.scrollable-content .carousel').owlCarousel({
            items: 1,
            nav 	: false,
            autoplay	: false,
            dots 	: true,
            responsiveRefreshRate   : 0
        });*/

        $('.js-products-carousel').each(function () {
            $(this).owlCarousel({
                nav: true,
                navText: [
                    '<svg class="d-block" xmlns="http://www.w3.org/2000/svg" width="24.824px" height="46.824px" viewBox="0 0 24.824 46.824"><path fill="currentColor" d="M0,23.406L23.406,0l1.414,1.414L2.828,23.406L24.82,45.405l-1.414,1.414L0,23.406z"/></svg>',
                    '<svg class="d-block" xmlns="http://www.w3.org/2000/svg" width="24.824px" height="46.824px" viewBox="0 0 24.824 46.824"><path fill="currentColor" d="M24.821,23.413L1.414,46.82L0,45.406l21.993-21.993L0,1.414L1.414,0L24.821,23.413z"/></svg>'
                ],
                loop: $(this).find('.card').length > 3,
                dots: false,
                responsive: {
                    0: {
                        items: 1,
                        margin: 30,
                    },
                    576: {
                        items: 2,
                        margin: 30,
                    },
                    992: {
                        items: 3,
                        margin: 42,
                    },
                    1200: {
                        items: 3,
                        margin: 83,
                    }
                },
            });
        });

        $('#media-slides-panel-carousel').owlCarousel({
            items: 1,
            nav 	: true,
            navText: [
              "<i class='fa fa-3x fa-angle-left'></i>",
              "<i class='fa fa-3x fa-angle-right'></i>"
            ],
            dots 	: false,
            video: true,
            margin: 10,
            responsive: {
                0: {
                    autoHeight: true
                },
                776: {
                    autoHeight: false
                }
            },
            onInitialized: navItems,
        });

        function navItems() {
            let carousel = document.querySelector('#media-slides-panel-carousel');

            if (carousel) {
                let items = carousel.querySelectorAll('.owl-item');
                let nav = carousel.querySelector('.owl-nav');

                if (items.length <= 1) {
                    nav.style.display = 'none';
                }
            }
        }

        $('#linkable-carousel-panels-carousel').owlCarousel({
            items: 3,
            nav 	: true,
            navText: [
              "<i class='fa fa-3x fa-angle-left'></i>",
              "<i class='fa fa-3x fa-angle-right'></i>"
            ],
            margin: 30,
            dots 	: true,
            video: true,
            loop: true,
            dotsEach: true,
            responsive: {
                0: {
                    items: 1,
                    margin: 0,
                },
                797: {
                    items: 2,
                    margin: 20,
                },
                1200: {
                    items: 3,
                    margin: 30,
                }
            }
        });

        $('#different-size-carousel.owl-carousel').owlCarousel({
            margin: 0,
            autoplay: false,
            center: false,
            rewind: true,
            nav: true,
            navText: [
              "<i class='fa fa-3x fa-angle-left'></i>",
              "<i class='fa fa-3x fa-angle-right'></i>"
            ],
            dots: false,
            onInitialized: owlInit,
            responsiveClass:true,
            rewindNav:false,
            rewindSpeed: 0,
            responsive:{
              0:{
                items:1,
              },
              600:{
                items:2,
                margin: 20,
              },
              992:{
                items:3,
              }
            }
        });

        $('#different-size-carousel.owl-carousel').each(function() {
            var $this = $(this);

            $this.on('initialize.owl.carousel initialized.owl.carousel translate.owl.carousel changed.owl.carousel', function(e) {
                idx = e.item.index;
                $this.find('.owl-item.big').removeClass('big');
                $this.find('.owl-item.medium').removeClass('medium');
                $this.find('.owl-item.small').removeClass('small');

                $this.find('.owl-item').eq(idx).addClass('big');
                $this.find('.owl-item').eq(idx + 1).addClass('medium');
                $this.find('.owl-item').eq(idx + 2).addClass('small');
            });

            $('#different-size-carousel.owl-carousel .img-wrapper').each( function( index ) {
                $(this).attr( 'data-position', index );
            });

            $(document).on('click', '.owl-item.active', function() {
                var $speed = 3000;
                $('#different-size-carousel.owl-carousel').trigger('to.owl.carousel', [$(this).find('.img-wrapper').data( 'position' ), $speed] );
            });
        });

        function owlInit() {
            $('#different-size-carousel.owl-carousel').each(function() {
                var $this = $(this);

                $this.find('.owl-item.active:eq(0)').addClass('big');
                $this.find('.owl-item.active:eq(1)').addClass('medium');
                $this.find('.owl-item.active:eq(2)').addClass('small');
            });
       }
    });
})(jQuery);
