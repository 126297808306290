(function($) {
    $(document).ready(function() {
        $('#main-carousel').on('initialized.owl.carousel', function(property){

            var carousel = $('#main-carousel');
            carousel.find(".owl-item").find(".title").css('opacity', '0');
            carousel.find(".owl-item").find(".text").css('opacity', '0');
            carousel.find(".owl-item").find(".btn-wrapper").css('opacity', '0');

            var current = property.item.index;
            var slide = $(property.target).find(".owl-item").eq(current);
            var title = $(property.target).find(".owl-item").eq(current).find(".title");
            var text = $(property.target).find(".owl-item").eq(current).find(".text");
            var btn = $(property.target).find(".owl-item").eq(current).find(".btn-wrapper");

            $(title).delay(750).queue(function (next) {
                $(this).css('opacity', '1');
                next();
            });

            $(text).delay(750).queue(function (next) {
                $(this).css('opacity', '1');
                next();
            });

            $(btn).delay(1250).queue(function (next) {
                $(this).css('opacity', '1');
                next();
            });
        });

        $('#main-carousel').owlCarousel({
            items: 1,
            nav 	: false,
            // navText: [
            //   "<i class='fa fa-3x fa-angle-left'></i>",
            //   "<i class='fa fa-3x fa-angle-right'></i>"
            // ],
            autoplay	: true,
            autoplayTimeout : 7000,
            autoplaySpeed : 1500,
            navSpeed : 1500,
            loop 	: $('.owl-carousel .item').length > 1 ? true : false,
            dots 	: true,
            responsiveRefreshRate   : 0
        });

        $('#main-carousel').on('changed.owl.carousel', function(property){

            var carousel = $('#main-carousel');
            carousel.find(".owl-item").find(".title").css('opacity', '0');
            carousel.find(".owl-item").find(".text").css('opacity', '0');
            carousel.find(".owl-item").find(".btn-wrapper").css('opacity', '0');

            var current = property.item.index;
            var slide = $(property.target).find(".owl-item").eq(current);
            var title = $(property.target).find(".owl-item").eq(current).find(".title");
            var text = $(property.target).find(".owl-item").eq(current).find(".text");
            var btn = $(property.target).find(".owl-item").eq(current).find(".btn-wrapper");

            $(title).delay(750).queue(function (next) {
                $(this).css('opacity', '1');
                next();
            });

            $(text).delay(750).queue(function (next) {
                $(this).css('opacity', '1');
                next();
            });

            $(btn).delay(1250).queue(function (next) {
                $(this).css('opacity', '1');
                next();
            });
        });

        $('#gallery-carousel, .gallery-carousel').owlCarousel({
            items: 1,
            nav 	: true,
            navText: [
              "<i class='fa fa-3x fa-angle-left'></i>",
              "<i class='fa fa-3x fa-angle-right'></i>"
            ],
            autoplay	:true,
            autoplayTimeout	: 6000,
            autoplaySpeed : 1500,
            navSpeed : 1500,
            loop 	: $('.owl-carousel .item').length > 1 ? true : false,
            dots 	: true,
            responsiveRefreshRate   : 0
        });

        /*$('#treatment-carousel').owlCarousel({
            items	: 1,
            nav 	: true,
            navText	: [
                "<i class='fa fa-chevron-left'></i>",
                "<i class='fa fa-chevron-right'></i>"
            ],
            loop 	: $('.owl-carousel .item').length > 1 ? true : false,
            dots 	: true,
            responsiveRefreshRate	: 0
        });*/

        /*$('.scrollable-content .carousel').owlCarousel({
            items: 1,
            nav 	: false,
            autoplay	: false,
            dots 	: true,
            responsiveRefreshRate   : 0
        });*/

        $('.js-products-carousel').each(function () {
            $(this).owlCarousel({
                nav: true,
                navText: [
                    '<svg class="d-block" xmlns="http://www.w3.org/2000/svg" width="24.824px" height="46.824px" viewBox="0 0 24.824 46.824"><path fill="currentColor" d="M0,23.406L23.406,0l1.414,1.414L2.828,23.406L24.82,45.405l-1.414,1.414L0,23.406z"/></svg>',
                    '<svg class="d-block" xmlns="http://www.w3.org/2000/svg" width="24.824px" height="46.824px" viewBox="0 0 24.824 46.824"><path fill="currentColor" d="M24.821,23.413L1.414,46.82L0,45.406l21.993-21.993L0,1.414L1.414,0L24.821,23.413z"/></svg>'
                ],
                loop: $(this).find('.card').length > 3,
                dots: false,
                responsive: {
                    0: {
                        items: 1,
                        margin: 30,
                    },
                    576: {
                        items: 2,
                        margin: 30,
                    },
                    992: {
                        items: 3,
                        margin: 42,
                    },
                    1200: {
                        items: 3,
                        margin: 83,
                    }
                },
            });
        });
    });
})(jQuery);
